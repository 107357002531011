"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.primitiveTokens = void 0;
exports.primitiveTokens = {
    'border': {
        'radius': {
            'none': { value: '0', type: 'borderRadius' },
            'xs': { value: '2px', type: 'borderRadius' },
            'sm': { value: '4px', type: 'borderRadius' },
            'md': { value: '6px', type: 'borderRadius' },
            'lg': { value: '8px', type: 'borderRadius' },
            'xl': { value: '12px', type: 'borderRadius' },
        },
    },
    'emerald': {
        '50': { value: '#ecfdf4', type: 'color' },
        '100': { value: '#d1fae5', type: 'color' },
        '200': { value: '#a7f3d0', type: 'color' },
        '300': { value: '#6ee7b7', type: 'color' },
        '400': { value: '#34d399', type: 'color' },
        '500': { value: 'red', type: 'color' },
        '600': { value: '#059669', type: 'color' },
        '700': { value: '#047857', type: 'color' },
        '800': { value: '#065f46', type: 'color' },
        '900': { value: '#064e3b', type: 'color' },
        '950': { value: '#022c22', type: 'color' },
    },
    'green': {
        '50': { value: '#f0fdf4', type: 'color' },
        '100': { value: '#dcfce7', type: 'color' },
        '200': { value: '#bbf7d0', type: 'color' },
        '300': { value: '#86efac', type: 'color' },
        '400': { value: '#4ade80', type: 'color' },
        '500': { value: '#22c55e', type: 'color' },
        '600': { value: '#16a34a', type: 'color' },
        '700': { value: '#15803d', type: 'color' },
        '800': { value: '#166534', type: 'color' },
        '900': { value: '#14532d', type: 'color' },
        '950': { value: '#052e16', type: 'color' },
    },
    'lime': {
        '50': { value: '#f7fee7', type: 'color' },
        '100': { value: '#ecfccb', type: 'color' },
        '200': { value: '#d9f99d', type: 'color' },
        '300': { value: '#bef264', type: 'color' },
        '400': { value: '#a3e635', type: 'color' },
        '500': { value: '#84cc16', type: 'color' },
        '600': { value: '#65a30d', type: 'color' },
        '700': { value: '#4d7c0f', type: 'color' },
        '800': { value: '#3f6212', type: 'color' },
        '900': { value: '#365314', type: 'color' },
        '950': { value: '#1a2e05', type: 'color' },
    },
    'red': {
        '50': { value: '#fef2f2', type: 'color' },
        '100': { value: '#fee2e2', type: 'color' },
        '200': { value: '#fecaca', type: 'color' },
        '300': { value: '#fca5a5', type: 'color' },
        '400': { value: '#f87171', type: 'color' },
        '500': { value: '#ef4444', type: 'color' },
        '600': { value: '#dc2626', type: 'color' },
        '700': { value: '#b91c1c', type: 'color' },
        '800': { value: '#991b1b', type: 'color' },
        '900': { value: '#7f1d1d', type: 'color' },
        '950': { value: '#450a0a', type: 'color' },
    },
    'orange': {
        '50': { value: '#fff7ed', type: 'color' },
        '100': { value: '#ffedd5', type: 'color' },
        '200': { value: '#fed7aa', type: 'color' },
        '300': { value: '#fdba74', type: 'color' },
        '400': { value: '#fb923c', type: 'color' },
        '500': { value: '#f97316', type: 'color' },
        '600': { value: '#ea580c', type: 'color' },
        '700': { value: '#c2410c', type: 'color' },
        '800': { value: '#9a3412', type: 'color' },
        '900': { value: '#7c2d12', type: 'color' },
        '950': { value: '#431407', type: 'color' },
    },
    'amber': {
        '50': { value: '#fffbeb', type: 'color' },
        '100': { value: '#fef3c7', type: 'color' },
        '200': { value: '#fde68a', type: 'color' },
        '300': { value: '#fcd34d', type: 'color' },
        '400': { value: '#fbbf24', type: 'color' },
        '500': { value: '#f59e0b', type: 'color' },
        '600': { value: '#d97706', type: 'color' },
        '700': { value: '#b45309', type: 'color' },
        '800': { value: '#92400e', type: 'color' },
        '900': { value: '#78350f', type: 'color' },
        '950': { value: '#451a03', type: 'color' },
    },
    'yellow': {
        '50': { value: '#fefce8', type: 'color' },
        '100': { value: '#fef9c3', type: 'color' },
        '200': { value: '#fef08a', type: 'color' },
        '300': { value: '#fde047', type: 'color' },
        '400': { value: '#facc15', type: 'color' },
        '500': { value: '#eab308', type: 'color' },
        '600': { value: '#ca8a04', type: 'color' },
        '700': { value: '#a16207', type: 'color' },
        '800': { value: '#854d0e', type: 'color' },
        '900': { value: '#713f12', type: 'color' },
        '950': { value: '#422006', type: 'color' },
    },
    'teal': {
        '50': { value: '#f0fdfa', type: 'color' },
        '100': { value: '#ccfbf1', type: 'color' },
        '200': { value: '#99f6e4', type: 'color' },
        '300': { value: '#5eead4', type: 'color' },
        '400': { value: '#2dd4bf', type: 'color' },
        '500': { value: '#14b8a6', type: 'color' },
        '600': { value: '#0d9488', type: 'color' },
        '700': { value: '#0f766e', type: 'color' },
        '800': { value: '#115e59', type: 'color' },
        '900': { value: '#134e4a', type: 'color' },
        '950': { value: '#042f2e', type: 'color' },
    },
    'cyan': {
        '50': { value: '#ecfeff', type: 'color' },
        '100': { value: '#cffafe', type: 'color' },
        '200': { value: '#a5f3fc', type: 'color' },
        '300': { value: '#67e8f9', type: 'color' },
        '400': { value: '#22d3ee', type: 'color' },
        '500': { value: '#06b6d4', type: 'color' },
        '600': { value: '#0891b2', type: 'color' },
        '700': { value: '#0e7490', type: 'color' },
        '800': { value: '#155e75', type: 'color' },
        '900': { value: '#164e63', type: 'color' },
        '950': { value: '#083344', type: 'color' },
    },
    'sky': {
        '50': { value: '#f0f9ff', type: 'color' },
        '100': { value: '#e0f2fe', type: 'color' },
        '200': { value: '#bae6fd', type: 'color' },
        '300': { value: '#7dd3fc', type: 'color' },
        '400': { value: '#38bdf8', type: 'color' },
        '500': { value: '#0ea5e9', type: 'color' },
        '600': { value: '#0284c7', type: 'color' },
        '700': { value: '#0369a1', type: 'color' },
        '800': { value: '#075985', type: 'color' },
        '900': { value: '#0c4a6e', type: 'color' },
        '950': { value: '#082f49', type: 'color' },
    },
    'blue': {
        '50': { value: '#eff6ff', type: 'color' },
        '100': { value: '#dbeafe', type: 'color' },
        '200': { value: '#bfdbfe', type: 'color' },
        '300': { value: '#93c5fd', type: 'color' },
        '400': { value: '#60a5fa', type: 'color' },
        '500': { value: '#3b82f6', type: 'color' },
        '600': { value: '#2563eb', type: 'color' },
        '700': { value: '#1d4ed8', type: 'color' },
        '800': { value: '#1e40af', type: 'color' },
        '900': { value: '#1e3a8a', type: 'color' },
        '950': { value: '#172554', type: 'color' },
    },
    'indigo': {
        '50': { value: '#eef2ff', type: 'color' },
        '100': { value: '#e0e7ff', type: 'color' },
        '200': { value: '#c7d2fe', type: 'color' },
        '300': { value: '#a5b4fc', type: 'color' },
        '400': { value: '#818cf8', type: 'color' },
        '500': { value: '#6366f1', type: 'color' },
        '600': { value: '#4f46e5', type: 'color' },
        '700': { value: '#4338ca', type: 'color' },
        '800': { value: '#3730a3', type: 'color' },
        '900': { value: '#312e81', type: 'color' },
        '950': { value: '#1e1b4b', type: 'color' },
    },
    'violet': {
        '50': { value: '#f5f3ff', type: 'color' },
        '100': { value: '#ede9fe', type: 'color' },
        '200': { value: '#ddd6fe', type: 'color' },
        '300': { value: '#c4b5fd', type: 'color' },
        '400': { value: '#a78bfa', type: 'color' },
        '500': { value: '#8b5cf6', type: 'color' },
        '600': { value: '#7c3aed', type: 'color' },
        '700': { value: '#6d28d9', type: 'color' },
        '800': { value: '#5b21b6', type: 'color' },
        '900': { value: '#4c1d95', type: 'color' },
        '950': { value: '#2e1065', type: 'color' },
    },
    'purple': {
        '50': { value: '#faf5ff', type: 'color' },
        '100': { value: '#f3e8ff', type: 'color' },
        '200': { value: '#e9d5ff', type: 'color' },
        '300': { value: '#d8b4fe', type: 'color' },
        '400': { value: '#c084fc', type: 'color' },
        '500': { value: '#a855f7', type: 'color' },
        '600': { value: '#9333ea', type: 'color' },
        '700': { value: '#7e22ce', type: 'color' },
        '800': { value: '#6b21a8', type: 'color' },
        '900': { value: '#581c87', type: 'color' },
        '950': { value: '#3b0764', type: 'color' },
    },
    'fuchsia': {
        '50': { value: '#fdf4ff', type: 'color' },
        '100': { value: '#fae8ff', type: 'color' },
        '200': { value: '#f5d0fe', type: 'color' },
        '300': { value: '#f0abfc', type: 'color' },
        '400': { value: '#e879f9', type: 'color' },
        '500': { value: '#d946ef', type: 'color' },
        '600': { value: '#c026d3', type: 'color' },
        '700': { value: '#a21caf', type: 'color' },
        '800': { value: '#86198f', type: 'color' },
        '900': { value: '#701a75', type: 'color' },
        '950': { value: '#4a044e', type: 'color' },
    },
    'pink': {
        '50': { value: '#fdf2f8', type: 'color' },
        '100': { value: '#fce7f3', type: 'color' },
        '200': { value: '#fbcfe8', type: 'color' },
        '300': { value: '#f9a8d4', type: 'color' },
        '400': { value: '#f472b6', type: 'color' },
        '500': { value: '#ec4899', type: 'color' },
        '600': { value: '#db2777', type: 'color' },
        '700': { value: '#be185d', type: 'color' },
        '800': { value: '#9d174d', type: 'color' },
        '900': { value: '#831843', type: 'color' },
        '950': { value: '#500724', type: 'color' },
    },
    'rose': {
        '50': { value: '#fff1f2', type: 'color' },
        '100': { value: '#ffe4e6', type: 'color' },
        '200': { value: '#fecdd3', type: 'color' },
        '300': { value: '#fda4af', type: 'color' },
        '400': { value: '#fb7185', type: 'color' },
        '500': { value: '#f43f5e', type: 'color' },
        '600': { value: '#e11d48', type: 'color' },
        '700': { value: '#be123c', type: 'color' },
        '800': { value: '#9f1239', type: 'color' },
        '900': { value: '#881337', type: 'color' },
        '950': { value: '#4c0519', type: 'color' },
    },
    'slate': {
        '50': { value: '#f8fafc', type: 'color' },
        '100': { value: '#f1f5f9', type: 'color' },
        '200': { value: '#e2e8f0', type: 'color' },
        '300': { value: '#cbd5e1', type: 'color' },
        '400': { value: '#94a3b8', type: 'color' },
        '500': { value: '#64748b', type: 'color' },
        '600': { value: '#475569', type: 'color' },
        '700': { value: '#334155', type: 'color' },
        '800': { value: '#1e293b', type: 'color' },
        '900': { value: '#0f172a', type: 'color' },
        '950': { value: '#020617', type: 'color' },
    },
    'gray': {
        '50': { value: '#f9fafb', type: 'color' },
        '100': { value: '#f3f4f6', type: 'color' },
        '200': { value: '#e5e7eb', type: 'color' },
        '300': { value: '#d1d5db', type: 'color' },
        '400': { value: '#9ca3af', type: 'color' },
        '500': { value: '#6b7280', type: 'color' },
        '600': { value: '#4b5563', type: 'color' },
        '700': { value: '#374151', type: 'color' },
        '800': { value: '#1f2937', type: 'color' },
        '900': { value: '#111827', type: 'color' },
        '950': { value: '#030712', type: 'color' },
    },
    'zinc': {
        '50': { value: '#fafafa', type: 'color' },
        '100': { value: '#f4f4f5', type: 'color' },
        '200': { value: '#e4e4e7', type: 'color' },
        '300': { value: '#d4d4d8', type: 'color' },
        '400': { value: '#a1a1aa', type: 'color' },
        '500': { value: '#71717a', type: 'color' },
        '600': { value: '#52525b', type: 'color' },
        '700': { value: '#3f3f46', type: 'color' },
        '800': { value: '#27272a', type: 'color' },
        '900': { value: '#18181b', type: 'color' },
        '950': { value: '#09090b', type: 'color' },
    },
    'neutral': {
        '50': { value: '#fafafa', type: 'color' },
        '100': { value: '#f5f5f5', type: 'color' },
        '200': { value: '#e5e5e5', type: 'color' },
        '300': { value: '#d4d4d4', type: 'color' },
        '400': { value: '#a3a3a3', type: 'color' },
        '500': { value: '#737373', type: 'color' },
        '600': { value: '#525252', type: 'color' },
        '700': { value: '#404040', type: 'color' },
        '800': { value: '#262626', type: 'color' },
        '900': { value: '#171717', type: 'color' },
        '950': { value: '#0a0a0a', type: 'color' },
    },
    'stone': {
        '50': { value: '#fafaf9', type: 'color' },
        '100': { value: '#f5f5f4', type: 'color' },
        '200': { value: '#e7e5e4', type: 'color' },
        '300': { value: '#d6d3d1', type: 'color' },
        '400': { value: '#a8a29e', type: 'color' },
        '500': { value: '#78716c', type: 'color' },
        '600': { value: '#57534e', type: 'color' },
        '700': { value: '#44403c', type: 'color' },
        '800': { value: '#292524', type: 'color' },
        '900': { value: '#1c1917', type: 'color' },
        '950': { value: '#0c0a09', type: 'color' },
    },
};
