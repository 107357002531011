"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.semanticTokens = void 0;
exports.semanticTokens = {
    'primary': {
        '50': { value: { "value": "{emerald.50}", "type": "other" }, type: '[object Object]' },
        '100': { value: { "value": "{emerald.100}", "type": "other" }, type: '[object Object]' },
        '200': { value: { "value": "{emerald.200}", "type": "other" }, type: '[object Object]' },
        '300': { value: { "value": "{emerald.300}", "type": "other" }, type: '[object Object]' },
        '400': { value: { "value": "{emerald.400}", "type": "other" }, type: '[object Object]' },
        '500': { value: { "value": "{emerald.500}", "type": "other" }, type: '[object Object]' },
        '600': { value: { "value": "{emerald.600}", "type": "other" }, type: '[object Object]' },
        '700': { value: { "value": "{emerald.700}", "type": "other" }, type: '[object Object]' },
        '800': { value: { "value": "{emerald.800}", "type": "other" }, type: '[object Object]' },
        '900': { value: { "value": "{emerald.900}", "type": "other" }, type: '[object Object]' },
        '950': { value: { "value": "{emerald.950}", "type": "other" }, type: '[object Object]' },
    },
    'anchor': {
        'gutter': { value: { "value": "2px", "type": "other" }, type: '[object Object]' },
    },
    'icon': {
        'size': { value: { "value": "1rem", "type": "other" }, type: '[object Object]' },
    },
    'disabled': {
        'opacity': { value: { "value": "0.6", "type": "other" }, type: '[object Object]' },
    },
    'form': {
        'field': {
            'padding': {
                'x': { value: { "value": "0.75rem", "type": "other" }, type: '[object Object]' },
                'y': { value: { "value": "0.5rem", "type": "other" }, type: '[object Object]' },
            },
            'border': {
                'radius': { value: { "value": "{border.radius.md}", "type": "other" }, type: '[object Object]' },
            },
            'focus': {
                'ring': {
                    'width': { value: { "value": "0", "type": "other" }, type: '[object Object]' },
                    'style': { value: { "value": "none", "type": "other" }, type: '[object Object]' },
                    'color': { value: { "value": "transparent", "type": "other" }, type: '[object Object]' },
                    'offset': { value: { "value": "0", "type": "other" }, type: '[object Object]' },
                    'shadow': { value: { "x": { "value": "0", "type": "other" }, "y": { "value": "0", "type": "other" }, "blur": { "value": "0", "type": "other" }, "spread": { "value": "0", "type": "other" }, "color": { "value": "rgba(0, 0, 0, 0)", "type": "other" }, "type": { "value": "dropShadow", "type": "other" } }, type: '[object Object]' },
                },
            },
            'sm': {
                'font': {
                    'size': { value: { "value": "0.875rem", "type": "other" }, type: '[object Object]' },
                },
                'padding': {
                    'x': { value: { "value": "0.625rem", "type": "other" }, type: '[object Object]' },
                    'y': { value: { "value": "0.375rem", "type": "other" }, type: '[object Object]' },
                },
            },
            'lg': {
                'font': {
                    'size': { value: { "value": "1.125rem", "type": "other" }, type: '[object Object]' },
                },
                'padding': {
                    'x': { value: { "value": "0.875rem", "type": "other" }, type: '[object Object]' },
                    'y': { value: { "value": "0.625rem", "type": "other" }, type: '[object Object]' },
                },
            },
        },
    },
    'content': {
        'border': {
            'radius': { value: { "value": "{border.radius.md}", "type": "other" }, type: '[object Object]' },
        },
    },
    'focus': {
        'ring': {
            'width': { value: { "value": "1", "type": "other" }, type: '[object Object]' },
            'color': { value: { "value": "{primary.color}", "type": "other" }, type: '[object Object]' },
            'offset': { value: { "value": "2", "type": "other" }, type: '[object Object]' },
            'shadow': { value: { "x": { "value": "0", "type": "other" }, "y": { "value": "0", "type": "other" }, "blur": { "value": "0", "type": "other" }, "spread": { "value": "0", "type": "other" }, "color": { "value": "rgba(0, 0, 0, 0)", "type": "other" }, "type": { "value": "dropShadow", "type": "other" } }, type: '[object Object]' },
            'stroke': { value: { "color": { "value": "{focus.ring.color}", "type": "other" }, "width": { "value": "{focus.ring.offset} + {focus.ring.width}", "type": "other" }, "style": { "value": "{focus.ring.style}", "type": "other" } }, type: '[object Object]' },
            'style': { value: { "value": "solid", "type": "other" }, type: '[object Object]' },
        },
    },
    'list': {
        'padding': { value: { "value": "0.25rem 0.25rem", "type": "other" }, type: '[object Object]' },
        'gap': { value: { "value": "2px", "type": "other" }, type: '[object Object]' },
        'header': {
            'padding': { value: { "value": "0.5rem 0.75rem 0.25rem 0.75rem", "type": "other" }, type: '[object Object]' },
        },
        'option': {
            'padding': { value: { "value": "0.5rem 0.75rem", "type": "other" }, type: '[object Object]' },
            'border': {
                'radius': { value: { "value": "{border.radius.sm}", "type": "other" }, type: '[object Object]' },
            },
            'group': {
                'padding': { value: { "value": "0.5rem 0.75rem", "type": "other" }, type: '[object Object]' },
                'font': {
                    'weight': { value: { "value": "600", "type": "other" }, type: '[object Object]' },
                },
            },
        },
    },
    'navigation': {
        'list': {
            'padding': { value: { "value": "0.25rem 0.25rem", "type": "other" }, type: '[object Object]' },
            'gap': { value: { "value": "2px", "type": "other" }, type: '[object Object]' },
        },
        'item': {
            'padding': { value: { "value": "0.5rem 0.75rem", "type": "other" }, type: '[object Object]' },
            'border': {
                'radius': { value: { "value": "{border.radius.sm}", "type": "other" }, type: '[object Object]' },
            },
            'gap': { value: { "value": "0.5rem", "type": "other" }, type: '[object Object]' },
        },
        'submenu': {
            'label': {
                'padding': { value: { "value": "0.5rem 0.75rem", "type": "other" }, type: '[object Object]' },
                'font': {
                    'weight': { value: { "value": "600", "type": "other" }, type: '[object Object]' },
                },
            },
            'icon': {
                'size': { value: { "value": "0.875rem", "type": "other" }, type: '[object Object]' },
            },
        },
    },
    'overlay': {
        'select': {
            'border': {
                'radius': { value: { "value": "{border.radius.md}", "type": "other" }, type: '[object Object]' },
            },
            'shadow': { value: { "0": { "x": { "value": "0", "type": "other" }, "y": { "value": "4", "type": "other" }, "blur": { "value": "6", "type": "other" }, "spread": { "value": "-1", "type": "other" }, "color": { "value": "rgba(0, 0, 0, 0.1)", "type": "other" }, "type": { "value": "dropShadow", "type": "other" } }, "1": { "x": { "value": "0", "type": "other" }, "y": { "value": "2", "type": "other" }, "blur": { "value": "4", "type": "other" }, "spread": { "value": "-2", "type": "other" }, "color": { "value": "rgba(0, 0, 0, 0.1)", "type": "other" }, "type": { "value": "dropShadow", "type": "other" } } }, type: '[object Object]' },
        },
        'popover': {
            'border': {
                'radius': { value: { "value": "{border.radius.md}", "type": "other" }, type: '[object Object]' },
            },
            'padding': { value: { "value": "0.75rem", "type": "other" }, type: '[object Object]' },
            'shadow': { value: { "0": { "x": { "value": "0", "type": "other" }, "y": { "value": "4", "type": "other" }, "blur": { "value": "6", "type": "other" }, "spread": { "value": "-1", "type": "other" }, "color": { "value": "rgba(0, 0, 0, 0.1)", "type": "other" }, "type": { "value": "dropShadow", "type": "other" } }, "1": { "x": { "value": "0", "type": "other" }, "y": { "value": "2", "type": "other" }, "blur": { "value": "4", "type": "other" }, "spread": { "value": "-2", "type": "other" }, "color": { "value": "rgba(0, 0, 0, 0.1)", "type": "other" }, "type": { "value": "dropShadow", "type": "other" } } }, type: '[object Object]' },
        },
        'modal': {
            'border': {
                'radius': { value: { "value": "{border.radius.xl}", "type": "other" }, type: '[object Object]' },
            },
            'padding': { value: { "value": "1.25rem", "type": "other" }, type: '[object Object]' },
            'shadow': { value: { "0": { "x": { "value": "0", "type": "other" }, "y": { "value": "20", "type": "other" }, "blur": { "value": "25", "type": "other" }, "spread": { "value": "-5", "type": "other" }, "color": { "value": "rgba(0, 0, 0, 0.1)", "type": "other" }, "type": { "value": "dropShadow", "type": "other" } }, "1": { "x": { "value": "0", "type": "other" }, "y": { "value": "8", "type": "other" }, "blur": { "value": "10", "type": "other" }, "spread": { "value": "-6", "type": "other" }, "color": { "value": "rgba(0, 0, 0, 0.1)", "type": "other" }, "type": { "value": "dropShadow", "type": "other" } } }, type: '[object Object]' },
        },
        'navigation': {
            'shadow': { value: { "0": { "x": { "value": "0", "type": "other" }, "y": { "value": "4", "type": "other" }, "blur": { "value": "6", "type": "other" }, "spread": { "value": "-1", "type": "other" }, "color": { "value": "rgba(0, 0, 0, 0.1)", "type": "other" }, "type": { "value": "dropShadow", "type": "other" } }, "1": { "x": { "value": "0", "type": "other" }, "y": { "value": "2", "type": "other" }, "blur": { "value": "4", "type": "other" }, "spread": { "value": "-2", "type": "other" }, "color": { "value": "rgba(0, 0, 0, 0.1)", "type": "other" }, "type": { "value": "dropShadow", "type": "other" } } }, type: '[object Object]' },
        },
    },
};
