import { HttpParams } from '@angular/common/http';
import { Component, inject, Input, OnInit, signal, WritableSignal } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { User } from '@app/shared/interfaces/user';
import { ZoomMeeting, ZoomMeetingLink } from '@app/shared/interfaces/zoom';
import { ProjectInfrastructureService } from '@app/shared/services/project-infrastructure.service';
import { UserService } from '@app/shared/services/user.service';
import { EMAIL_REGEX } from '@app/shared/utils/regex';
import { AuthService } from '@auth0/auth0-angular';
import { DateTime } from 'luxon';
import { TooltipOptions } from 'primeng/api';
import { catchError, debounceTime, Observable, of, switchMap, take, tap } from 'rxjs';

@Component({
  selector: 'lfx-meeting-form',
  templateUrl: './meeting-form.component.html',
  styleUrls: ['./meeting-form.component.scss']
})
export class MeetingFormComponent implements OnInit {
  @Input() public meeting: ZoomMeeting;
  @Input() public password: string = '';

  public zoomLink$: Observable<ZoomMeetingLink>;
  public showGuestForm: boolean = false;
  public form: FormGroup;
  public inviteForm: FormGroup;
  public inviteFormLoading: boolean = false;
  public meetingLink: string;
  public meetingError: boolean = false;
  public meetingErrorMessage: string = '';
  public meetingLinkLoading: boolean = false;
  public supportLink: string = 'https://jira.linuxfoundation.org/plugins/servlet/theme/portal/4';
  public showInvite: boolean = false;
  public inviteRequested: boolean = false;
  public inviteError: boolean = false;
  public inviteNameError: string = '';
  public inviteEmailError: string = '';
  public tenMinWarning: string = 'You may only join the meeting up to 10 minutes before the start time';
  public tooltipOptions: TooltipOptions = { tooltipPosition: 'bottom', appendTo: 'body' };
  public skipAuth0: WritableSignal<boolean> = signal(false);
  public user$: Observable<User | null>;
  public user: User | null | undefined;

  private authService = inject(AuthService);
  private pisService = inject(ProjectInfrastructureService);
  private fb = inject(FormBuilder);
  private activatedRoute = inject(ActivatedRoute);
  private userService = inject(UserService);

  public constructor() {
    this.skipAuth0 = this.userService.skipAuth0;
    this.form = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.pattern(EMAIL_REGEX)]],
      organization: ['']
    });

    this.inviteForm = this.fb.group({
      name: ['', [Validators.required, Validators.pattern(/\w+\s+\w+/)]],
      email: ['', [Validators.required, Validators.pattern(EMAIL_REGEX)]]
    });

    this.showInvite = this.activatedRoute.snapshot.queryParams.invite === 'true';

    this.inviteForm.valueChanges.pipe(debounceTime(250)).subscribe(() => {
      this.inviteError = false;

      if (this.inviteForm.controls.name.hasError('pattern')) {
        this.inviteNameError = 'Please enter a valid first and last name';
      } else {
        this.inviteNameError = '';
      }

      if (this.inviteForm.controls.email.hasError('pattern')) {
        this.inviteEmailError = 'Please enter a valid email address';
      } else {
        this.inviteEmailError = '';
      }
    });
  }

  public get formDisabled(): boolean {
    return this.form.invalid || this.meetingLinkLoading;
  }

  public get inviteFormDisabled(): boolean {
    return this.inviteForm.invalid || this.inviteFormLoading;
  }

  public get meetingStartTime(): DateTime {
    if (this.meeting.occurrences) {
      return DateTime.fromISO(this.meeting.occurrences[0].start_time);
    }

    return DateTime.fromISO(this.meeting.start_time);
  }

  public get meetingEnded(): boolean {
    const meetingStartTime = this.meetingStartTime;
    const meetingEndTime = meetingStartTime.plus({ minutes: this.meeting.duration }).plus({ minutes: 40 });

    return DateTime.local() > meetingEndTime;
  }

  public get canJoinMeeting(): boolean {
    const meetingStartTime = this.meetingStartTime;
    const meetingStartTimeBuffer = meetingStartTime.minus({ minutes: this.meeting.early_join_time || 10 });

    return DateTime.local() > meetingStartTimeBuffer && !this.meetingEnded;
  }

  public ngOnInit(): void {
    if (this.meeting.restricted) {
      this.form.controls.email.addValidators(Validators.required);
    }

    if (this.meeting.early_join_time && this.meeting.early_join_time !== 10) {
      this.tenMinWarning = `You may only join the meeting up to ${this.meeting.early_join_time} minutes before the start time`;
    }

    this.user$ = this.authService.isLoading$.pipe(
      switchMap((isLoading) => {
        if (isLoading) {
          return of(false);
        }

        return this.authService
          .getAccessTokenSilently({
            detailedResponse: true
          })
          .pipe(
            catchError((e) => {
              console.info('User is not authenticated - ', e.message);
              return of(false);
            })
          );
      }),
      switchMap((user) => {
        if (user) {
          const params = new HttpParams({
            fromObject: {
              basic: true
            }
          });
          return this.userService.getMe(params);
        }

        return of({});
      }),
      tap((user) => {
        this.user = user;
        if (this.user) {
          this.inviteForm.patchValue({
            name: this.user.Name || '',
            email: this.user.Email || ''
          });

          this.inviteForm.markAsDirty();
        }
      })
    );

    this.zoomLink$ = this.pisService.getMeetingLink(this.meeting.id).pipe(
      catchError((e) => of({ link: 'error', message: this.getErrorCode(e.error.message) })),
      tap((link) => {
        if (link.link !== 'error') {
          let name = this.user?.Name?.trim() || '';

          // If organization is not empty, append it to the name
          if (this.user?.Account?.Name?.trim() && this.user?.Account?.Name !== 'Individual - No Account') {
            name = `${name} (${this.user.Account.Name})`;
          }

          // Base64 Encode Name
          const encodedName = btoa(unescape(encodeURIComponent(name)));
          const queryParams = new HttpParams().set('uname', name).set('un', encodedName);
          const queryString = queryParams.toString();
          // If link.link has a query string, append it to the link
          if (link.link.includes('?')) {
            this.meetingLink = `${link.link}&${queryString}`;
          } else {
            this.meetingLink = `${link.link}?${queryString}`;
          }

          window.location.href = this.meetingLink;
        } else {
          this.meetingError = true;
          this.meetingErrorMessage = link.message;
        }
      })
    );
  }

  public getMeetingStartTime(): string {
    const meetingStartTime = this.meetingStartTime;

    return meetingStartTime.toLocaleString({ weekday: 'long', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' });
  }

  public onLogin(createAccount: boolean = false): void {
    const meetingData: { [key: string]: string } = {
      meetingID: this.meeting.id
    };

    if (createAccount) {
      meetingData.signup = 'true';
    }

    if (this.password) {
      meetingData.password = this.password;
    }

    localStorage.setItem('meetingData', JSON.stringify(meetingData));

    this.authService.loginWithRedirect({
      authorizationParams: {
        ...meetingData,
        prompt: 'login',
        screen_hint: createAccount ? 'signup' : 'login'
      },
      appState: {
        ...meetingData,
        returnTo: window.location.href
      }
    });
  }

  public onToggleGuestMode(): void {
    this.showGuestForm = !this.showGuestForm;
  }

  public onJoinMeeting(): void {
    if (this.form.valid) {
      this.meetingLinkLoading = true;
      let params = new HttpParams();
      let name = this.form.controls.name.value.trim();

      // If organization is not empty, append it to the name
      if (this.user?.Account?.Name?.trim() && this.user?.Account?.Name !== 'Individual - No Account') {
        name = `${name} (${this.form.value.organization.trim()})`;
      }

      if (this.meeting.visibility === 'public') {
        // If the meeting is public, we need to pass the name
        params = params.set('name', name);

        // Pass the email if it's not empty
        if (this.form.controls.email.value) {
          params = params.set('email', this.form.controls.email.value.trim());
        }
      } else {
        // If the meeting is private, we need to pass the email
        params = params.set('name', name).set('email', this.form.controls.email.value.trim());
      }

      // Get Meeting Link
      this.pisService
        .getPublicMeetingLink(this.meeting.id, params)
        .pipe(
          take(1),
          catchError((e) => of({ link: 'error', message: this.getErrorCode(e.error.message) }))
        )
        .subscribe((link) => {
          if (link.link !== 'error') {
            // Base64 Encode Name
            const encodedName = btoa(unescape(encodeURIComponent(name)));
            const queryParams = new HttpParams().set('uname', name).set('un', encodedName);
            const queryString = queryParams.toString();
            // If link.link has a query string, append it to the link
            if (link.link.includes('?')) {
              this.meetingLink = `${link.link}&${queryString}`;
            } else {
              this.meetingLink = `${link.link}?${queryString}`;
            }

            window.location.href = this.meetingLink;
          } else {
            this.meetingLink = link.link;
            this.meetingErrorMessage = link.message;
            this.meetingError = true;
          }

          this.meetingLinkLoading = false;
        });
    }
  }

  public onRequestInvite(): void {
    if (this.inviteForm.valid) {
      this.inviteFormLoading = true;

      const data = {
        name: this.inviteForm.controls.name.value.trim(),
        email: this.inviteForm.controls.email.value.trim()
      };

      this.pisService
        .postRequestInvite(this.meeting.id, data)
        .pipe(
          catchError((e) => {
            this.inviteError = true;
            return of(e);
          })
        )
        .subscribe((res) => {
          if (res.status === 200) {
            this.showInvite = false;
            this.inviteRequested = true;
          }

          this.inviteFormLoading = false;
        });
    }
  }

  private getErrorCode(message: string): string {
    if (message.includes('meeting cannot be joined because it is not scheduled for this time')) {
      return 'past-meeting';
    }

    if (
      ['Bad Request: user does not have access to join meeting', 'Bad Request: insufficient information to determine user access to meeting'].includes(message)
    ) {
      return 'not-invited';
    }

    if (message.includes('invalid email address')) {
      return 'invalid-email';
    }

    if (message.includes('user not found for user ID')) {
      return 'user-not-found';
    }

    return 'error';
  }
}
